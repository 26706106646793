import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import axios from "axios";
import { useSelector } from "react-redux";

const OtherUserDetails = () => {
  const [loading, setLoading] = useState(() => false);
  const [error, setError] = useState(() => "");
  const [success, setSuccess] = useState(() => false);
  const [street, setStreet] = useState(() => "");
  const [city, setCity] = useState(() => "");
  const [state, setState] = useState(() => "");
  const [zip, setZip] = useState(() => "");
  const [countryList, setCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [dialCode, setDialCode] = useState("");
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    axios.get(`api/miscellaneous/country-codes`)
      .then((res) => {
        setCountryList(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      axios
        .get(`/api/users/social-address`, config)
        .then((res) => {
          console.log(res.data[0]);
          setStreet(res.data[0].street);
          setCity(res.data[0].city);
          setState(res.data[0].state);
          setCountryCode(res.data[0].country);
          setZip(res.data[0].zip);
        })
        .catch((err) => {
          console.error(err)
        });
    }
  }, []);

  const errorHandler = (msg) => {
    setError(msg);
    setTimeout(() => {
      setError("");
    }, 4500);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!street) {
      errorHandler("Enter Street");
      return;
    }
    if (!city) {
      errorHandler("Enter City");
      return;
    }
    if (!state) {
      errorHandler("Enter State");
      return;
    }
    if (!countryCode) {
      errorHandler("Select Country");
      return;
    }
    if (!zip) {
      errorHandler("Enter Zip");
      return;
    }

    let address = {
      street,
      city,
      state,
      countryCode,
      dialCode,
      zip,
    };

    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      axios
        .put(`/api/users/social-address`, address, config)
        .then((res) => {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 4500);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          errorHandler("Cannot Update Address");
        });
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountryId = e.target.value;
    const selectedCountry = countryList.find((country) => country._id === selectedCountryId);
    if (selectedCountry) {
      setCountryCode(selectedCountry._id);
      setDialCode(selectedCountry.dial_code);
    }
  };

  return (
    <div className="paymentMethod-main">
      <h4 className="heading">
        <span>User Other Details</span>
      </h4>
      <div className="account-personalInfo">
        {error && <Message variant="danger">{error}</Message>}
        {success && <Message variant="success">Updated</Message>}
        {loading ? (
          <Loader />
        ) : (
          <Form onSubmit={submitHandler}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="name">
                  <Form.Label>Street</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Enter Street"
                    value={street}
                    onChange={(e) => {
                      setStreet(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="lastName">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter State"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="countryCode">
                  <Form.Label>Country</Form.Label>
                  <select
                    className="form-control"
                    value={countryCode}
                    onChange={handleCountryChange}
                  >
                    <option value="">Select Country</option>
                    {countryList.map((country) => (
                      <option key={country._id} value={country._id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Zip"
                    value={zip}
                    onChange={(e) => {
                      setZip(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}></Col>
              <Col md={12} className="text-center">
                <Button type="submit" variant="primary">
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </div>
  );
};

export default OtherUserDetails;
