import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import VendorDrawerForm from "./../components/vendorDrowerForm";
import { EVENT_URL } from "../urls/OtherUrls";

const Footer = () => {
  let history = useHistory();
  const location = useLocation();

  const userInfo = useSelector((state) => state.userLogin.userInfo);

  const [email, setEmail] = useState(() => "");
  const [success, setSuccess] = useState(() => false);
  const [error, setError] = useState(() => false);
  const [msg, setMsg] = useState(() => "");
  const [contactInfo, setContactInfo] = useState(() => { });
  const [categories, setCategories] = useState(() => []);

  useEffect(() => {
    window.scrollTo(0, 0);

    Axios.get("/api/miscellaneous/contact-info")
      .then((res) => {
        setContactInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get(`/api/category/getall`)
      .then((res) => {
        setCategories((old) => [...old, ...res.data.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!email) {
      setError(true);
      setMsg("Please enter an email");
      setTimeout(reset, 3000);
    }

    let response = await Axios.post("/api/miscellaneous/subscribe", {
      email: email,
    });
    setEmail("");
    if (response.data.type == "success") {
      setSuccess(true);
      setMsg(response.data.message);
      setTimeout(reset, 2000);
    } else {
      setError(true);
      setMsg(response.data.message);
      setTimeout(reset, 2000);
    }
  };

  const reset = () => {
    setMsg("");
    setError(false);
    setSuccess(false);
  };

  const handleClick = (name, id) => {
    history.push(`/category/${name}/${id}`);
  };

  return (
    <footer
      className="footer-main"
      style={{
        display: location.pathname === "/admin/login" ? "none" : "block",
      }}
    >
      <section className="footer-link-wraper">
        <Container className="container">
          <Row>
            <Col md={12} lg={8}>
              <Row>
                <Col md={3}>
                  <div className="footer-links">
                    <h4>Find it Fast</h4>
                    <ul>
                      {categories.length != 0 ? (
                        categories
                          .filter((e1, i) => i <= 7)
                          .map((e, i) => (
                            <li key={i}>
                              <Link to={`/category/${e.slug}/${e._id}`}>
                                {e.name}
                              </Link>
                            </li>
                          ))
                      ) : (
                        <></>
                      )}
                      {/* <li><Link to='/'>Accessories</Link></li>
                                        <li><Link to='/'>Gaming</Link></li>
                                        <li><Link to='/'>Laptops & Computer</Link></li>
                                        <li><Link to='/'>Mac Computers</Link></li>
                                        <li><Link to='/'>PC Computers</Link></li>
                                        <li><Link to='/'>Ultrabooks</Link></li>
                                        <li><Link to='/'>EMI Payment</Link></li>
                                        <li><Link to='/'>Shipping Policy</Link></li> */}
                    </ul>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="footer-links">
                    <h4>Sell on Danzflo</h4>
                    <ul>
                      {userInfo && (
                        <li>
                          <VendorDrawerForm />
                        </li>
                      )}

                      <li>
                        <Link to="/how-does-it-work">How Does It Work</Link>
                      </li>
                      {/* <li><Link to='/'>Pricing</Link></li> */}
                      {/* <li><Link to='/'>Fulfillment by Shop Clues</Link></li> */}
                      {/* <li><Link to='/'>Merchant Tools</Link></li> */}
                      <li>
                        <Link to="/privacy-policy">Policies& Rules</Link>
                      </li>
                      {/* <li><Link to='/'>Testimonials</Link></li>
                                        <li><Link to='/'>Merchant Ratings</Link></li> */}
                    </ul>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="footer-links">
                    <h4>Information</h4>
                    <ul>
                      {/* <li><Link to='/'>About Us</Link></li>
                                        <li><Link to='/'>Contact Us</Link></li> */}
                      {/* <li><Link to='/'>All Collection</Link></li> */}
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms-conditions">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="/cookie-policy">Cookie Policy</Link>
                      </li>
                      {/* <li><Link to='/'>Blog</Link></li> */}
                      {/* <li><Link to='/'>FAQ</Link></li> */}
                      {/* <li><Link to='/'>Awards</Link></li> */}
                    </ul>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="footer-links">
                    <h4>Quick Links</h4>
                    <ul>
                      {/* <li><Link to='/'>About Us</Link></li>
                                        <li><Link to='/'>Contact Us</Link></li> */}
                      {/* <li><Link to='/'>All Collection</Link></li> */}
                      <li>
                        <a href={`${EVENT_URL}/guard/login`} >Login as guard</a>
                      </li>
                      {/* <li>
                        <Link to="/terms-conditions">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="/cookie-policy">Cookie Policy</Link>
                      </li> */}
                      {/* <li><Link to='/'>Blog</Link></li> */}
                      {/* <li><Link to='/'>FAQ</Link></li> */}
                      {/* <li><Link to='/'>Awards</Link></li> */}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={12} lg={4}>
              <div className="footer-right-border">
                <div className="footer-contactInfo">
                  <h4>Contact Us</h4>
                  <p>
                    If you have any question, please contact us at{" "}
                    <a href={`https://danzflo.com/support`}>Support</a>
                  </p>
                  <ul>
                    <li>
                      <i className="las la-map-marker"></i>{" "}
                      {contactInfo ? (
                        <>{`${contactInfo.street1} ${contactInfo.street2}, ${contactInfo.city}, ${contactInfo.state}`}</>
                      ) : (
                        <></>
                      )}
                    </li>
                    {/* <li><i className='las la-mobile'></i> <a href={`tel: ${contactInfo?contactInfo.phone: <></>}`}>{contactInfo?contactInfo.phone: <></>}</a></li> */}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          {/* <div className='footer-newsletter'>
                    <Row>
                        <Col>
                        {
                            success || error?
                                <Alert variant={success? "success": error? "danger": ""}>{msg}</Alert>
                                :
                                <></>
                        }
                          
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={6} xl={5}>
                            <div className='media'>
                                <div className='mr-3'>
                                    <div className='media leftSide'>
                                        <div className='mr-3'>
                                            <svg width='35' height='34' viewBox='0 0 35 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path d='M7 18L15.332 23.5546C16.6498 24.4332 18.362 24.4514 19.6983 23.6011L28.5 18' stroke='#2AA8F2' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
                                                <path d='M1.5 28.5V13.994C1.5 13.3679 1.79321 12.7779 2.29228 12.3998L14.2614 3.3323C16.458 1.66817 19.5058 1.71439 21.651 3.44438L32.7555 12.3996C33.2263 12.7793 33.5 13.3516 33.5 13.9564V28.5C33.5 30.7091 31.7091 32.5 29.5 32.5H5.5C3.29086 32.5 1.5 30.7091 1.5 28.5Z' stroke='#2AA8F2' strokeWidth='1.5'/>
                                            </svg>
                                        </div>
                                        <div className='media-body'>
                                        <h5 className='mt-0'>Sign up for <br />NEWSLETTER</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='media-body content-rightSide'>
                                    <h5 className='mt-0'>Subscribe to the weekly newsletter for all the latest updates</h5>
                                </div>
                            </div>                        
                        </Col>
                        <Col md={12} lg={6} xl={5}>
                            <Form>
                                <Form.Group className='search-wraper'>
                                    <Form.Control  className='search_query form-control' type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="Your email..." />
                                    <Button variant="btn" type='submit' onClick={submitHandler}>Submit</Button>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>                
                </div> */}
          {/* <Row>
                    <Col md={12}>
                        <div className='footer-helpLinks'>
                            <ul>
                                <li><Link to='/'>Home</Link></li>
                                <li><Link to='/'>Shop</Link></li>
                                <li><Link to='/'>About</Link></li>
                                <li><Link to='/'>Blog</Link></li>
                                <li><Link to='/'>Contact</Link></li>
                            </ul>
                        </div>
                    </Col>
                </Row> */}
        </Container>
      </section>
      <section className="copyright-main">
        <Container>
          <Row>
            <Col md={6}>
              <div className="copyright">
                <p>
                  Copyright © 2022 <Link to="/">DanzFlo</Link> All Rights
                  Reserved
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="social-links">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/DanzFlo-112915663809273"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f">facebook</i>
                    </a>
                  </li>
                  {/* <li><Link to='/'><i className='fab fa-twitter'>twitter</i></Link></li> */}
                  <li>
                    <a
                      href="https://instagram.com/danzflo?r=nametag"
                      target="_blank"
                    >
                      <i className="fab fa-instagram">instagram</i>
                    </a>
                  </li>
                  {/* <li><Link to='/'><i className='fab fa-youtube'>youtube</i></Link></li> */}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
